<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-1">
        From the list below, select the <b>best</b> explanation for why
        <stemble-latex :content="molecule1" />
        has a higher boiling point than
        <stemble-latex :content="molecule2" />
        .
      </p>

      <v-radio-group v-model="inputs.input1" class="pl-10" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question251',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          // 1 = stronger dispersion vs weaker dispersion forces
          return {
            molecule1: '$\\text{ethanol, }\\ce{C2H5OH,}$',
            molecule2: '$\\text{methanol, }\\ce{CH3OH}$',
            options1: [
              {
                expression: '$\\text{Methanol cannot hydrogen bond as it is too small}$',
                value: 'a',
              },
              {
                expression: '$\\text{Ethanol is more polar than methanol}$',
                value: 'b',
              },
              {
                expression: '$\\text{Ethanol is polar while methanol is non-polar}$',
                value: 'c',
              },
              {
                expression:
                  '$\\text{Ethanol is larger and has more electrons leading to stronger dispersion forces}$',
                value: 'd',
              },
            ],
          };
        case 2:
          // 2 = hydrogen bonding vs dipole dipole
          return {
            molecule1: '$\\text{ethanol, }\\ce{C2H5OH,}$',
            molecule2: '$\\text{dimethyl ether, }\\ce{H3C}\\text{-O-}\\ce{CH3}$',
            options1: [
              {
                expression:
                  '$\\text{Ethanol is larger and has more electrons leading to stronger dispersion forces}$',
                value: 'a',
              },
              {
                expression:
                  '$\\text{Hydrogen bonding is possible in ethanol but not dimethyl ether}$',
                value: 'b',
              },
              {
                expression: '$\\text{Ethanol is polar while dimethyl ether is non-polar}$',
                value: 'c',
              },
              {
                expression: '$\\text{Ethanol has the longer hydrocarbon chain of the two}$',
                value: 'd',
              },
            ],
          };
        case 3:
          // 3 = dipole dipole vs dispersion
          return {
            molecule1: '$\\text{fluoromethane, }\\ce{CH3F,}$',
            molecule2: '$\\text{methane, }\\ce{CH4}$',
            options1: [
              {
                expression: '$\\text{Fluoromethane has hydrogen bonding}$',
                value: 'a',
              },
              {
                expression:
                  '$\\text{Fluoromethane is larger and has more electrons leading to stronger dispersion forces}$',
                value: 'b',
              },
              {
                expression: '$\\text{Fluoromethane is polar while methane is non-polar}$',
                value: 'c',
              },
              {
                expression: '$\\text{Fluoromethane is less polar than methane}$',
                value: 'd',
              },
            ],
          };
        default:
          return {
            molecule1: null,
            molecule2: null,
            options1: [
              {expression: '', value: 'a'},
              {expression: '', value: 'b'},
              {expression: '', value: 'c'},
              {expression: '', value: 'd'},
            ],
          };
      }
    },
    molecule1() {
      return this.versionData.molecule1;
    },
    molecule2() {
      return this.versionData.molecule2;
    },
    options1() {
      return this.versionData.options1;
    },
  },
};
</script>
